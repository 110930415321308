<div class="perk-tabber">
  <div class="col-2 p-0 m-0 plan-nav back">
    <button type="button" class="prk-btn-td" aria-label="Back" (click)="changeValue(false)" placement="bottom">
      <i class="mdi mdi-chevron-left" aria-hidden="true"></i>
    </button>
  </div>
  <div class="col-8 p-0 m-0 desc">{{selectedLabel()}}</div>
  <div class="col-2 p-0 m-0 plan-nav next">
    <button type="button" class="prk-btn-td" aria-label="Back" (click)="changeValue(true)" placement="bottom">
      <i class="mdi mdi-chevron-right" aria-hidden="true"></i>
    </button>
  </div>
</div>