import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  constructor() { }

  @Input()
  files;

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      if ((element.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || element.type == "application/vnd.ms-excel") && this.files.length < 1) {
        this.files.push(element);
      }
    }
  }
  deleteAttachment(index) {
    this.files.splice(index, 1)
  }

  ngOnInit(): void {
  }

}
