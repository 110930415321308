<div class="upload-container" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
  <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)"
    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"><div
    class="upload-message">Arrastra tu archivo acá</div>
</div>
<div class="file-list" *ngFor="let file of files; let i = index">
  <span> {{ file.name }} </span>
  <a class="delete-file" (click)="deleteAttachment(i)">
    <img src="/assets/images/shared/Trash.svg">
  </a>
</div>