import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
  name: 'rut'
})
export class RutPipe implements PipeTransform {
  constructor (private utils: UtilsService) {}

  transform(rut: any): any {
    let rutAux = this.utils.cleanRut(rut);
    var result = this.utils.cleanRut(rut).length > 1 ? rutAux.slice(-4, -1) + '-' + rutAux.substr(rutAux.length - 1) : this.utils.cleanRut(rut);
    for (var i = 4; i < rutAux.length; i += 3) {
      result = rutAux.slice(-3 - i, -i) + '.' + result;
    }
    return result;
  }

}
