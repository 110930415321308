import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'seguro-form',
  templateUrl: './seguro.component.html',
  styleUrls: ['./seguro.component.scss']
})
export class SeguroComponent implements OnInit {

  @Input() data: any;
  @Input() saveChanges: () => void;
  @Input() planes: any[];
  @Input() editPlan: boolean = false;
  selectedPlan: any = undefined;

  get blockButton() {
    if (this.data.plan != "") {
      return false;
    } else {
      return true;
    }
  }

  constructor(private utilsService: UtilsService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      if (this.data.plan && this.data.plan._id) {
        this.data.planId = this.data.plan._id;
      }
    }
  }

  selectPlan() {
    this.selectedPlan = this.planes.find(plan => plan._id === this.data.planId);
  }

  getVigencia(vigencia) {
    return this.utilsService.getVigencia(vigencia);
  }

}
