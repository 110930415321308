import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonaComponent } from './persona/persona.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { PlanComponent } from './plan/plan.component';
import { SeguroComponent } from './seguro/seguro.component';
import { GrupoComponent } from './grupo/grupo.component';
import { EquipoComponent } from './equipo/equipo.component';
import { DeportistaFormComponent } from './deportista-form/deportista-form.component';
import { TagInputModule } from 'ngx-chips';

@NgModule({
  declarations: [PersonaComponent, EmpresaComponent, PlanComponent, SeguroComponent, GrupoComponent, EquipoComponent, DeportistaFormComponent],
  exports: [PersonaComponent, EmpresaComponent, PlanComponent, SeguroComponent, GrupoComponent, EquipoComponent, DeportistaFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    TagInputModule,
  ]
})
export class PerkFormsModule { }
