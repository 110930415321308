import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'user-nav',
  templateUrl: './user-nav.component.html',
  styleUrls: ['./user-nav.component.scss']
})
export class UserNavComponent implements OnInit {

  @Input() entity: string;
  @Input() aseguradora: string;
  @ViewChild('closeRedirect') closeRedirect: ElementRef;
  items: any[] = []

  constructor() { }

  ngOnInit(): void {
    switch (this.entity) {
      case 'team':
        this.items = [
          {
            text: 'Editar',
            link: '/usuario/team/editar'
          },
          {
            text: 'Integrantes',
            link: '/usuario/team/integrantes'
          }
          // {
          //   text: 'Pagos',
          //   link: '/usuario/deudas'
          // },
          // {
          //   text: 'Deudas Asociados',
          //   link: '/usuario/deudas-asociados'
          // }
        ]
        break;

      case 'grouper':
        this.items = [
          {
            text: 'Editar',
            link: '/usuario/grouper/editar'
          },
          // {
          //   text: 'Pagos',
          //   link: '/usuario/deudas'
          // },
          {
            text: 'Asociados',
            link: '/usuario/grouper/asociados'
          },
          {
            text: 'Api Keys',
            link: '/usuario/grouper/api-key'
          }
          // {
          //   text: 'Deudas Asociados',
          //   link: '/usuario/deudas-asociados'
          // }
        ]
        break;

      case 'admin':
        this.items = [
          {
            text: 'Deudas',
            link: '/usuario/admin/deudas'
          },
          {
            text: 'Agregar/quitar',
            link: '/usuario/admin/agrupadores'
          },
          {
            text: 'Seguros',
            link: '/usuario/admin/seguros'
          }
        ]
        break;
    }
  }

  reportSouthbridge = (): void => {
    window.open('https://www.southbridgeseguros.cl/denuncia-siniestro/', '_blank');
    setTimeout(() => {
      if (this.closeRedirect) {
        this.closeRedirect.nativeElement.click();
      }
    }, 3000);
  }

  reportHDI = (): void => {
    window.open('https://www.hdi.cl/hdi-seguros-home/denunciar-siniestros/denuncia-aqu%C3%AD/siniestro-ramos-varios/', '_blank');
    setTimeout(() => {
      if (this.closeRedirect) {
        this.closeRedirect.nativeElement.click();
      }
    }, 3000);
  }

  reportAccident = (): void => {
    if (this.aseguradora === 'Southbridge') {
      document.getElementById('openSouthbridge').click();
    } else if (this.aseguradora === 'HDI') {
      document.getElementById('openHDI').click();
    }
  }

}