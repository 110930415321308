<form class="prk-form">
    <div class="prk-label">
        <div class="col-3 p-0">Nombre</div>
        <div class="col p-0"><input type="text" #nombre="ngModel" name="nombre" required placeholder="Nombre"
                [(ngModel)]="data.name">
            <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="prk-form-alert">
                <div *ngIf="nombre.errors.required">
                    El nombre es requerido
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Apellido</div>
        <div class="col p-0"><input type="text" #apellido="ngModel" name="apellido" required placeholder="Apellido"
                [(ngModel)]="data.surname">
            <div *ngIf="apellido.invalid && (apellido.dirty || apellido.touched)" class="prk-form-alert">
                <div *ngIf="apellido.errors.required">
                    El apellido es requerido
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">RUT</div>
        <div class="col p-0"><input type="text" #rut="ngModel" name="rut" required placeholder="RUT"
                [ngModel]="data.rut | rut" (ngModelChange)="data.rut=utils.cleanRut($event)">
            <div *ngIf="(rut.invalid || validateRut(data.rut)) && (rut.dirty || rut.touched)" class="prk-form-alert">
                Ingrese un RUT válido
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Correo</div>
        <div class="col p-0"><input type="email" #correo="ngModel" name="correo" required
                placeholder="Correo electrónico" [(ngModel)]="data.email">
            <div *ngIf="correo.invalid && (correo.dirty || correo.touched)" class="prk-form-alert">
                <div *ngIf="correo.errors.required">
                    El correo electrónico es requerido
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Nacimiento</div>
        <div class="col p-0"><input type="date" #nacimiento="ngModel" name="nacimiento" required
                placeholder="Fecha de nacimiento" [(ngModel)]="data.birthdate">
            <div *ngIf="nacimiento.invalid && (nacimiento.dirty || nacimiento.touched)" class="prk-form-alert">
                <div *ngIf="nacimiento.errors.required">
                    La fecha de nacimiento es requerida
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Región</div>
        <div class="col p-0">
            <select #region="ngModel" name="region" [(ngModel)]="data.region" (change)="fillComunes()"
                class="prk-select">
                <option hidden selected [value]="undefined">Selecciona una región</option>
                <option *ngFor="let region of regionsData" [value]="region.name">{{region.name}}</option>
            </select>
            <div *ngIf="region.invalid && (region.dirty || region.touched)" class="prk-form-alert">
                <div *ngIf="data.region.errors.required">
                    La región es requerida
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Comuna</div>
        <div class="col p-0">
            <select #comuna="ngModel" [(ngModel)]="data.commune" name="comuna" class="prk-select">
                <option hidden selected [value]="undefined">Selecciona una comuna</option>
                <option *ngFor="let comuna of comunesData" [value]="comuna.name">{{comuna.name|titlecase}}</option>
            </select>
            <div *ngIf="comuna.invalid && (comuna.dirty || comuna.touched)" class="prk-form-alert">
                <div *ngIf="comuna.errors.required">
                    La comuna es requerida
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Dirección</div>
        <div class="col p-0"><input type="text" #direccion="ngModel" name="direccion" required placeholder="Dirección"
                [(ngModel)]="data.address">
            <div *ngIf="direccion.invalid && (direccion.dirty || direccion.touched)" class="prk-form-alert">
                <div *ngIf="direccion.errors.required">
                    La dirección es requerida
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Teléfono</div>
        <div class="col p-0"><input type="text" #telefono="ngModel" name="telefono" required
                placeholder="Número de teléfono" [(ngModel)]="data.phoneNumber">
            <div *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)" class="prk-form-alert">
                <div *ngIf="telefono.errors.required">
                    El teléfono es requerido
                </div>
            </div>
        </div>
    </div>

    <div class="prk-label">
        <div class="col-3 p-0">Previsión</div>
        <div class="col p-0">
            <select [(ngModel)]="data.forecast" #prevision="ngModel" name="prevision" class="prk-select">
                <option hidden selected [value]="undefined">Selecciona tu previsión</option>
                <option>Fonasa</option>
                <option>Isapre</option>
            </select>
            <div *ngIf="prevision.invalid && (prevision.dirty || prevision.touched)" class="prk-form-alert">
                <div *ngIf="prevision.errors.required">
                    Debe ingresar su previsión
                </div>
            </div>
        </div>
    </div>

    <br *ngIf="agreement">
    <label *ngIf="agreement"><input type="checkbox" [(ngModel)]="data.agree" name="agreement">Acepto que quise editar
        estos datos y me hago responsable por la veracidad de la información cambiada.</label>
    <button class="prk-btn center" [disabled]="blockButton" (click)="saveChanges()">Aceptar</button>
</form>