import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'plan-form',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {

  @Input() data: any;
  @Input() saveChanges: () => void;
  newItem: any = {
    description: '',
    amount: ''
  }
  newValue: any = {
    validity: {
      quantity: '',
      unit: undefined
    },
    price: ''
  }

  get blockButton() {
    if (this.data.nombre != "" && this.data.aseguradora != "" && this.data.tipoDeSeguro != "") {
      return false;
    } else {
      return true;
    }
  }

  get blockAddCoberturaButton() {
    return (this.newItem.description === '' || this.newItem.amount === '');
  }

  get blockAddValorButton() {
    return (this.newValue.validity.quantity === '' || this.newValue.validity.unit === '' || this.newValue.price === '');
  }

  constructor(private utilsService: UtilsService) { }

  ngOnInit(): void {
  }

  addCobertura() {
    this.data.coverage.push(JSON.parse(JSON.stringify(this.newItem)));
    this.newItem = {
      description: '',
      amount: ''
    };
  }

  addValor() {
    this.data.values.push(JSON.parse(JSON.stringify(this.newValue)));
    this.newValue = {
      validity: {
        quantity: '',
        unit: undefined
      },
      price: ''
    };
  }

  removeCobertura(item) {
    this.data.coverage.splice(this.data.coverage.indexOf(item), 1);
  }

  removeValor(item) {
    this.data.values.splice(this.data.values.indexOf(item), 1);
  }

  getVigencia(validity) {
    return this.utilsService.getVigencia(validity);
  }

  addSport = (value: string): Observable<string> => {
    this.data.filters.sports.push(value);
    return of(value);
  }

  removeSport = (value: string): Observable<string> => {
    this.data.filters.sports.splice(this.data.filters.sports.indexOf(value), 1);
    return of(value);
  }

}
