import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedIn: boolean = this.getToken() != null ? true : false;
  private url: string = environment.urlBack;

  constructor(private http: HttpClient) { }

  login(user: any) {
    let params = new HttpParams({
      fromObject: { email: user.mail, password: user.password },
    });
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    };
    return this.http.post<any>(`${this.url}/user/login`, params.toString(), httpOptions).pipe(
      map(
        data => {
          this.setToken(data.accessToken);
          this.loggedIn = true;
          return data;
        },
        error => {
          return error;
        }
      )
    );
  }

  async logout() {
    this.deleteToken();
  }

  setToken(token: string) {
    localStorage.setItem('accessToken', JSON.stringify(token));
  }

  getToken() {
    return JSON.parse(localStorage.getItem('accessToken'));
  }

  deleteToken() {
    localStorage.removeItem('accessToken');
    setTimeout(() => {
      this.loggedIn = false;
    }, 10000)
  }

  getInfo() {
    return this.http.get<any>(`${this.url}/user/verifyToken`, {
      headers: { 'x-access-token': this.getToken() }
    });
  }

  getUser(id: string) {
    return this.http.get<any>(`${this.url}/user/get/${id}`, {
      headers: { 'x-access-token': this.getToken() },
    });
  }

  getAll() {
    return this.http.get<any>(`${this.url}/user/get`, {
      headers: { 'x-access-token': this.getToken() },
    });
  }

  recoverPassword(mail: string) {
    return this.http.post<any>(`${this.url}/user/recoverPassword`, { 'email': mail });
  }

  updatePassword(info: any) {
    const json = {
      currentPassword: info.current,
      newPassword: info.new
    }
    return this.http.put<any>(`${this.url}/user/updatePassword`, json, {
      headers: { 'x-access-token': this.getToken() },
    });
  }

  deleteUser(id: string) {
    return this.http.delete<any>(`${this.url}/user/delete/${id}`, {
      headers: { 'x-access-token': this.getToken() },
    });
  }

  // Invitations
  createInvitation(invitation: any) {
    return this.http.post<any>(`${this.url}/invitation/create`, invitation, {
      headers: { 'x-access-token': this.getToken() },
    });
  }

  updateInvitation(invitation: string, emailList: string[]) {
    return this.http.put<any>(`${this.url}/invitation/update`, { invitation, emailList }, {
      headers: { 'x-access-token': this.getToken() },
    });
  }

  getInvitations() {
    return this.http.get<any>(`${this.url}/invitation/getByUser`, {
      headers: { 'x-access-token': this.getToken() }
    });
  }

  getInvitationData(invitation) {
    return this.http.post<any>(`${this.url}/invitation/get`, { invitation }, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  signUpInvitation(user: any, invitation: string) {
    const json = {
      invitation: invitation,
      newUser: user
    };
    let httpOptions = {
      headers: new HttpHeaders({}),
    };
    return this.http.post<any>(`${this.url}/invitation/signup`, json, httpOptions);
  }

  deleteInvitation(id: string) {
    return this.http.delete<any>(`${this.url}/invitation/delete/${id}`, {
      headers: { 'x-access-token': this.getToken() },
    });
  }

  // Api Keys stuff
  createApiKey() {
    return this.http.post<any>(`${this.url}/apiKey/create`, {}, {
      headers: { 'x-access-token': this.getToken() },
    });
  }

  getApiKey() {
    return this.http.get<any>(`${this.url}/apiKey/get`, {
      headers: { 'x-access-token': this.getToken() }
    });
  }
}
