<app-navbar></app-navbar>
<router-outlet></router-outlet>
<app-footer></app-footer>

<div class="modal fade prk-modal" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="loginModalLabel"
  aria-hidden="true">
  <div class="modal-dialog prk-dialog" role="document">
    <div class="modal-content prk-content">
      <app-login></app-login>
    </div>
  </div>
</div>