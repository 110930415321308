<ul class="nav nav-pills nav-fill user-nav">
  <li class="nav-item pill" *ngFor="let item of items">
    <a class="nav-link" [routerLink]="item.link">{{item.text}}</a>
  </li>
  <li class="nav-item pill accident" *ngIf="aseguradora" (click)="reportAccident()">
    <a class="nav-link">Denunciar siniestro<i class="mdi mdi-bell-alert" aria-hidden="true"></i></a>
  </li>
</ul>

<button id="openSouthbridge" [hidden]="true" data-toggle="modal" data-target="#southbridgeModal"></button>
<button id="openHDI" [hidden]="true" data-toggle="modal" data-target="#hdiModal"></button>

<div class="modal fade" id="southbridgeModal" tabindex="-1" role="dialog" aria-labelledby="redirectLabel"
  aria-hidden="true">
  <div class="modal-dialog prk-dialog" role="document">
    <div class="modal-content prk-content">
      <div class="modal-header prk-header">
        <h1 class="bold">Redirigiendo<span class="primary">...</span></h1>
        <button [hidden]="true" type="button" class="close" data-dismiss="modal" #closeRedirect aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <p>Te redirigiremos al sitio web de la aseguradora. Una vez allí, debes elegir la <span class="bold">opción de
          siniestro personas</span> y rellenar el formulario.</p>
      <button class="prk-btn condensed center" (click)="reportSouthbridge()">¡Entendido!</button>
    </div>
  </div>
</div>

<div class="modal fade" id="hdiModal" tabindex="-1" role="dialog" aria-labelledby="redirectLabel" aria-hidden="true">
  <div class="modal-dialog prk-dialog" role="document">
    <div class="modal-content prk-content">
      <div class="modal-header prk-header">
        <h1 class="bold">Redirigiendo<span class="primary">...</span></h1>
        <button [hidden]="true" type="button" class="close" data-dismiss="modal" #closeRedirect aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <p>Te redirigiremos al sitio web de la aseguradora. Una vez allí, debes <span class="bold">descargar el
          formulario, completarlo y enviarlo</span> a la dirección de correo que se indique.</p>
      <button class="prk-btn condensed center" (click)="reportHDI()">¡Entendido!</button>
    </div>
  </div>
</div>