<form class="prk-form" *ngIf="data && extraRole">

    <!-- Insured form -->
    <h2 class="text-left">Datos de {{titleTexts[extraRole]}}</h2>
    <div class="prk-label">
        <div class="col-3 p-0">Nombre</div>
        <div class="col p-0">
            <input type="text" #nombre="ngModel" name="nombre" required placeholder="Nombre"
                [(ngModel)]="data.nombre" />
            <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="prk-form-alert">
                <div *ngIf="nombre.errors.required">El nombre es requerido</div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Región</div>
        <div class="col p-0">
            <select #region="ngModel" name="region" [(ngModel)]="data.region" (change)="fillComunes()"
                class="prk-select">
                <option hidden selected [value]="undefined">
                    Selecciona una región
                </option>
                <option *ngFor="let region of regionsData" [value]="region.name">
                    {{ region.name }}
                </option>
            </select>
            <div *ngIf="region.invalid && (region.dirty || region.touched)" class="prk-form-alert">
                <div *ngIf="data.region.errors.required">La región es requerida</div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Comuna</div>
        <div class="col p-0">
            <select #comuna="ngModel" [(ngModel)]="data.comuna" name="comuna" class="prk-select">
                <option hidden selected [value]="undefined">
                    Selecciona una comuna
                </option>
                <option *ngFor="let comuna of comunesData" [value]="comuna.name">
                    {{ comuna.name | titlecase }}
                </option>
            </select>
            <div *ngIf="comuna.invalid && (comuna.dirty || comuna.touched)" class="prk-form-alert">
                <div *ngIf="comuna.errors.required">La comuna es requerida</div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Dirección</div>
        <div class="col p-0">
            <input type="text" #direccion="ngModel" name="direccion" required placeholder="Dirección"
                [(ngModel)]="data.direccion" />
            <div *ngIf="direccion.invalid && (direccion.dirty || direccion.touched)" class="prk-form-alert">
                <div *ngIf="direccion.errors.required">La dirección es requerida</div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Teléfono</div>
        <div class="col p-0">
            <input type="text" #telefono="ngModel" name="telefono" required placeholder="Número de teléfono"
                [(ngModel)]="data.telefono" />
            <div *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)" class="prk-form-alert">
                <div *ngIf="telefono.errors.required">El teléfono es requerido</div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Correo</div>
        <div class="col p-0">
            <input type="email" #correo="ngModel" name="correo" required placeholder="Correo electrónico"
                [(ngModel)]="data.email" />
            <div *ngIf="correo.invalid && (correo.dirty || correo.touched)" class="prk-form-alert">
                <div *ngIf="correo.errors.required">
                    El correo electrónico es requerido
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Contraseña</div>
        <div class="col p-0">
            <input type="password" #password="ngModel" name="password" required placeholder="Contraseña"
                [(ngModel)]="data.password" />
            <div *ngIf="password.invalid && (password.dirty || password.touched)" class="prk-form-alert">
                <div *ngIf="password.errors.required">La contraseña es obligatoria</div>
            </div>
        </div>
    </div>
    <div class="prk-label" *ngIf="extraRole === 'evento'">
        <div class="col-3 p-0">Fecha inicio de vigencia</div>
        <div class="col p-0">
            <input type="date" #inicioVigencia="ngModel" name="inicioVigencia" required
                placeholder="Fecha inicio de vigencia" [(ngModel)]="data.inicioVigencia" />
            <div *ngIf="
          inicioVigencia.invalid &&
          (inicioVigencia.dirty || inicioVigencia.touched)
        " class="prk-form-alert">
                <div *ngIf="nacimiento.errors.required">
                    La fecha inicio de vigencia es requerida
                </div>
            </div>
        </div>
    </div>
    <button class="prk-btn center" [disabled]="blockButton" (click)="pushChanges()">
        Aceptar
    </button>
</form>