<div class="footer">
	<div class="row footer-cont">
		<div class="col-sm">
			<img src="assets/images/Logo_Perk_dark.svg" class="footer-logo" alt="Perk">
			<div class="row support">
				<img src="assets/images/about/Corfo.png" class="footer-support" alt="CORFO">
				<img src="assets/images/about/OpenBeauchef.png" class="footer-support" alt="OpenBeauchef">
			</div>
		</div>
		<div class="col-sm">
			<div class="row">
				<div class="col-sm">
					<ul>
						<li><a class="footer-link" routerLink="/">Home</a></li>
						<li><a class="footer-link" routerLink="/" fragment="seguros">Seguros</a></li>
						<li><a class="footer-link" routerLink="/" fragment="faq">FAQ</a></li>
					</ul>
				</div>
				<div class="col-sm">
					<ul>
						<li><a class="footer-link" routerLink="/nosotros">Nosotros</a></li>
						<li><a class="footer-link" routerLink="/" fragment="contacto">Contacto</a></li>
					</ul>
				</div>
			</div>
		</div>
		<div class="col-sm centered">
			<div>
				<p>Inscríbete a nuestra newsletter</p>
				<input [(ngModel)]="email" type="text" placeholder="Email"><button id="news-btn" value="value" type="submit"
					(click)="subscribe()">➔</button>
			</div>
		</div>
	</div>
	<div class="freepik-disclaimer">Iconos hechos por <a href="https://www.freepik.com" title="Freepik">Freepik</a> de <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
</div>