<div class="prk-form">
  <div class="prk-label">
    <div class="col-3 p-0">Nombre</div>
    <div class="col p-0"><input type="text" #name="ngModel" required placeholder="Nombre" [(ngModel)]="data.name">
      <div *ngIf="name.invalid && (name.dirty || name.touched)" class="prk-form-alert">
        <div *ngIf="name.errors.required">
          El nombre es requerido
        </div>
      </div>
    </div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Aseguradora</div>
    <div class="col p-0">
      <select style="width: 100%;" [(ngModel)]="data.insuranceCarrier" #insuranceCarrier="ngModel" required>
        <option hidden selected [value]="undefined">Seleccione una aseguradora</option>
        <option value="HDI">HDI</option>
        <option value="Southbridge">Southbridge</option>
      </select>
      <div *ngIf="insuranceCarrier.invalid && (insuranceCarrier.dirty || insuranceCarrier.touched)" class="prk-form-alert">
        <div *ngIf="insuranceCarrier.errors.required">
          Debe seleccionar una aseguradora
        </div>
      </div>
    </div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Categoría</div>
    <div class="col p-0">
      <select style="width: 100%;" [(ngModel)]="data.type" #tipo="ngModel" required>
        <option hidden selected [value]="undefined">Seleccione una categoría</option>
        <option value="sport">Deporte</option>
      </select>
      <div *ngIf="tipo.invalid && (tipo.dirty || tipo.touched)" class="prk-form-alert">
        <div *ngIf="tipo.errors.required">
          Debe seleccionar una categoría
        </div>
      </div>
    </div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Beneficiario(s)</div>
    <div class="col-9 p-0">
      <label><input type="checkbox" [(ngModel)]="data.hasBeneficiaries">El seguro requiere beneficiario(s)</label>
    </div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Valor</div>
    <div class="col-2 p-0">
      <input type="number" placeholder="Cantidad" min="1" [(ngModel)]="newValue.validity.quantity">
    </div>
    <div class="col-3 p-0">
      <select style="width: 100%;" [(ngModel)]="newValue.validity.unit" #tipo="ngModel" required>
        <option hidden selected [value]="undefined">Unidad</option>
        <option value="D">Día(s)</option>
        <option value="M">Mes(es)</option>
        <option value="Y">Año(s)</option>
      </select>
    </div>
    <div class="col-2 p-0">
      <input type="number" placeholder="Precio" min="0" [(ngModel)]="newValue.price">
    </div>
    <div class="col-2 p-0">
      <button class="prk-btn center condensed" style="padding: 0.2rem 3rem;" [disabled]="blockAddValorButton"
        (click)="addValor()">
        <i class="mdi mdi-plus" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Cobertura</div>
    <div class="col-5 p-0"><input type="text" placeholder="Descripción" [(ngModel)]="newItem.descripcion">
    </div>
    <div class="col-2 p-0"><input type="number" placeholder="Monto" [(ngModel)]="newItem.monto">
    </div>
    <div class="col-2 p-0">
      <button class="prk-btn center condensed" style="padding: 0.2rem 3rem;" [disabled]="blockAddCoberturaButton"
        (click)="addCobertura()">
        <i class="mdi mdi-plus" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <!-- Filters -->
  <div class="prk-label">
    <div class="col-3 p-0">Edad mínima</div>
    <div class="col p-0"><input type="number" #minAge="ngModel" required placeholder="Edad mínima permitida"
        [(ngModel)]="data.filters.minAge"></div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Edad máxima</div>
    <div class="col p-0"><input type="number" #maxAge="ngModel" required placeholder="Edad máxima permitida"
        [(ngModel)]="data.filters.maxAge"></div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Deportes</div>
    <div class="col p-0">
      <tag-input [ngModel]="data.filters.sports" [onAdding]="addSport" [onRemoving]="removeSport"
        placeholder="Agregar deporte" secondaryPlaceholder="Agrega un deporte"></tag-input>
    </div>
  </div>

  <!-- Table that displays 'values' array items -->
  <h3 *ngIf="data.values.length > 0" class="mt-4 bold">Valores</h3>
  <table *ngIf="data.values.length > 0">
    <thead>
      <tr>
        <th scope="col">Vigencia</th>
        <th scope="col">Costo</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data.values">
        <td>{{getVigencia(item.validity)}}</td>
        <td>{{item.price | currency:'UF ':'code'}}</td>
        <td class="last">
          <button type="button" class="prk-btn-td" aria-label="Remove" (click)="removeValor(item)">
            <i class="mdi mdi-close" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Table that displays 'coverage' array items -->
  <h3 *ngIf="data.coverage.length > 0" class="mt-4 bold">Cobertura</h3>
  <table *ngIf="data.coverage.length > 0">
    <thead>
      <tr>
        <th scope="col">Descripción</th>
        <th scope="col">Monto</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data.coverage">
        <td>{{item.description}}</td>
        <td>{{item.amount | currency:'UF ':'code'}}</td>
        <td class="last">
          <button type="button" class="prk-btn-td" aria-label="Remove" (click)="removeCobertura(item)">
            <i class="mdi mdi-close" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <button class="prk-btn center" [disabled]="blockButton" (click)="saveChanges()">Aceptar</button>
</div>