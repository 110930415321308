import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabber',
  templateUrl: './tabber.component.html',
  styleUrls: ['./tabber.component.scss']
})
export class TabberComponent implements OnInit {

  @Input() values: any[] = [];
  @Input() selectedValue: any;
  @Input() changeValue: (value: any) => void;

  constructor() { }

  ngOnInit(): void {
    // this.changeValue(null);
  }

  ngAfterViewInit() {
    // this.changeValue(null);
  }

  selectedLabel() {
    var vigenciaString: string = '';
    if (this.values) {
      var vigencia = this.values.find(value => value._id === this.selectedValue);
      if (vigencia) {
        vigencia = vigencia.validity;
        switch (vigencia.unit) {
          case 'D':
            vigenciaString = `${vigencia.quantity} día${vigencia.quantity === 1 ? '' : 's'}`;
            break;
          case 'M':
            vigenciaString = `${vigencia.quantity} mes${vigencia.quantity === 1 ? '' : 'es'}`;
            break;
          case 'Y':
            vigenciaString = `${vigencia.quantity} año${vigencia.quantity === 1 ? '' : 's'}`;
            break;
        }
      }
      return vigenciaString;
    }
  }

}
