<nav class="navbar navbar-expand-lg navbar-light fixed-top" id='navbar-perk' (scroll)="onWindowScroll($event);">
  <a class="navbar-brand" routerLink="/">
    <img src="assets/images/Logo_Perk_black.svg" class="perk-logo" alt="Perk">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="dataToggle()" #closeNavbar
    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav flex-row ml-md-auto">
      <li class="nav-item active">
        <a class="nav-link" (click)="close()" [routerLink]="homeLink">Home</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" (click)="close()" routerLink="/nosotros">Nosotros</a>
      </li>
      <li class="nav-item active" *ngIf="!isAdmin">
        <a class="nav-link" (click)="close()" routerLink="/api">Docs</a>
      </li>
      <li class="nav-item active" *ngIf="!isAdmin">
        <a class="nav-link" (click)="close()" routerLink="/" fragment="contacto">Contáctanos</a>
      </li>
      <li class="nav-item active" *ngIf="!isAdmin">
        <a class="nav-link" (click)="close()" routerLink="/" fragment="faq">FAQ </a>
      </li>
      <li class="nav-item dropdown active" *ngIf="isAdmin">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Ver
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="close()" routerLink="/usuario/admin/agrupadores">Agrupadores</a>
          <a class="dropdown-item" (click)="close()" routerLink="/usuario/admin/personas">Personas</a>
          <a class="dropdown-item" (click)="close()" routerLink="/usuario/admin/equipos">Equipos</a>
        </div>
      </li>
    </ul>
    <button *ngIf="!isLoggedIn; else elseBlock" class="prk-btn my-2 my-sm-0" data-toggle="modal"
      data-target="#loginModal" #openLoginModal>Log In</button>
    <ng-template #elseBlock><button class="prk-btn white my-2 my-sm-0" (click)="logout()">Log Out</button></ng-template>
  </div>
</nav>