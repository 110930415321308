<div class="prk-form">

  <div class="prk-label" *ngIf="planes && planes.length > 0">
    <div class="col-3 p-0">Plan</div>
    <div class="col p-0">
      <select style="width: 100%;" [(ngModel)]="data.planId" #seguro="ngModel" name="seguro" [disabled]="!editPlan" (change)="selectPlan()">
        <option hidden selected [value]="undefined">Seleccione un plan</option>
        <option *ngFor="let plan of planes" [value]="plan._id">{{plan.name | titlecase}}</option>
      </select>
    </div>
  </div>

  <div class="prk-label" *ngIf="data.planId && editPlan">
    <div class="col-3 p-0">Vigencia</div>
    <div class="col p-0">
      <select style="width: 100%;" [(ngModel)]="data.validity" #vigencia="ngModel" name="vigencia" [disabled]="!editPlan">
        <option hidden selected [value]="undefined">Seleccione una vigencia</option>
        <option *ngFor="let item of selectedPlan.values" [ngValue]="item.validity">{{getVigencia(item.validity)}}</option>
      </select>
    </div>
  </div>

  <div class="prk-label" *ngIf="!editPlan && data.validity">
    <div class="col-3 p-0">Vigencia</div>
    <div class="col p-0">
      <select style="width: 100%;" [(ngModel)]="data.validity" #vigencia="ngModel" name="vigencia" [disabled]="!editPlan">
        <option [ngValue]="data.validity">{{getVigencia(data.validity)}}</option>
      </select>
    </div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Fecha inicio</div>
    <div class="col p-0"><input type="date" #inicio="ngModel" name="inicio" placeholder="Fecha de inicio"
        [(ngModel)]="data.startDate">
    </div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Fecha fin</div>
    <div class="col p-0"><input type="date" #fin="ngModel" name="fin" placeholder="Fecha de fin"
        [(ngModel)]="data.endDate">
    </div>
  </div>

  <button class="prk-btn center" [disabled]="blockButton" (click)="saveChanges()">Aceptar</button>
</div>