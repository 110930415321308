import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { VaultPerkService } from 'src/app/services/vault-perk.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  newEntidad: any = {
    name: '',
    rut: '',
    address: '',
    region: undefined,
    commune: undefined,
    phoneNumber: '',
    email: '',
    forecast: undefined,
    birthdate: '',
    startDate: '',
  };
  plan: any;
  invitation: any;
  invalidInvitation: boolean;
  loading: boolean = true;
  blockVigencia: boolean = true;

  constructor(private authService: AuthService, private route: ActivatedRoute, private titleService: Title, private vaultPerkService: VaultPerkService, private router: Router) { }

  ngOnInit(): void {
    let invitation = this.route.snapshot.paramMap.get('id');
    this.route.queryParams.subscribe(params => {
      this.newEntidad.name = params['name'] ? params['name'] : '';
      this.newEntidad.surname = params['surname'] ? params['surname'] : '';
      this.newEntidad.rut = params['rut'] ? params['rut'] : '';
      this.newEntidad.region = params['region'] ? params['region'] : undefined;
      this.newEntidad.commune = params['commune'] ? params['commune'] : undefined;
      this.newEntidad.address = params['address'] ? params['address'] : '';
      this.newEntidad.phoneNumber = params['phoneNumber'] ? params['phoneNumber'] : '';
      this.newEntidad.email = params['email'] ? params['email'] : '';
      this.newEntidad.forecast = params['forecast'] ? params['forecast'] : undefined;
      this.newEntidad.birthdate = params['birthdate'] ? params['birthdate'] : '';
    });
    this.authService.getInvitationData(invitation).subscribe(response => {
      if (response.status === 'ok') {
        this.invalidInvitation = false;
        this.titleService.setTitle('Regístrate | Perk');
        this.invitation = response.data;
        this.vaultPerkService.getPlanById(response.data.planId).subscribe(res => {
          if (res.status === 'ok') {
            this.plan = {
              name: res.data.name,
              validity: this.invitation.life,
              values: [{ validity: this.invitation.life }]
            };
            this.newEntidad.startDate = this.invitation.startDate ? this.invitation.startDate.split('T')[0] : new Date().toISOString().split('T')[0];
            this.blockVigencia = this.invitation.inicio ? true : false;
            this.loading = false;
          }
        }, err => {
          // Handling error
        });
      } else {
        this.invalidInvitation = true;
        this.loading = false;
      }
    }, error => {
      this.loading = false;
      this.invalidInvitation = true;
    });
  }

  create = (): void => {
    this.authService.signUpInvitation(this.newEntidad, this.route.snapshot.paramMap.get('id')).subscribe(res => {
      if (res.status === 'ok') {
        this.swalAlert('ok');
      } else {
        this.swalAlert('error');
      }
    }, error => {
      this.swalAlert('error');
    });
  }

  swalAlert(status: string): void {
    if (status === 'ok') {
      Swal.fire({
        icon: 'success',
        title: '¡Listo!',
        text: 'Ya estás registrado en Perk. Revisa la bandeja de entrada de tu correo electrónico para activar tu cuenta.',
      }).then(() => {
        this.router.navigate(['/'], { relativeTo: this.route });
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: '¡Ups!',
        text: 'Ha ocurrido un error al registrar tu usuario. Verifica que tus datos estén correctos e intenta nuevamente.',
      });
    }
  }

}
