<div class="mt">
  <div class="signup centered p-4rem">
    <div class="cont max-width-1200">
      <div class="card">
        <div class="mt-3 mx-4" *ngIf="invalidInvitation">
          <h1 class="bold">Enlace inválido ¡Ups!</h1>
          <h4>La invitación que estás buscando no es válida. Corrobora el enlace que te proporcionó tu proveedor.</h4>
        </div>
        <div *ngIf="!invalidInvitation && !loading">
          <h1 class="bold mt-3 mx-4">¡Hola, te damos la bienvenida a P<span class="primary">erk</span>!</h1>
          <app-deportista-form [data]="newEntidad" [plan]="plan" [blockVigencia]="blockVigencia" [saveChanges]="create">
          </app-deportista-form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-map></app-map>