import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  @Input() list: any[] = [];
  firstElement: any;
  lastElement: any;
  elements: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.fillElements(this.list);
    setTimeout(() => {
      this.onResize();
    }, 1);
  }

  @HostListener("window:resize", [])
  public onResize() {
    const width = window.innerWidth;
    this.initTimeline(width);
  }

  initTimeline(x): void {
    let cont = 0;
    let aux = document.getElementsByClassName('timeline-desc');
    var items = Array.prototype.slice.call(aux);
    if (x > 980) {
      for (var j = 0; j < items.length; j++) {
        cont = items[j].offsetHeight > cont ? items[j].offsetHeight : cont;
      }
      for (var i = 0; i < items.length; i++) {
        items[i].style.height = x > 980 ? cont + "px" : "auto";
      }
    } else {
      for (var k = 0; k < items.length; k++) {
        cont = items[k].offsetWidth > cont ? items[k].offsetWidth : cont;
      }
      for (var l = 0; l < items.length; l++) {
        items[l].style.width = cont + "px";
      }
    }
  }


  fillElements(list) {
    this.firstElement = list[0];
    this.lastElement = list[list.length - 1];
    for (var i = 0; i < list.length; i++) {
      if (i != 0 && i != list.length - 1) {
        this.elements.push(list[i]);

      }
    }
  }
}
