import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('closeLoginModal') closeLoginModal: ElementRef;

  data = {
    mail: "",
    password: "",
    remember: false
  };
  alertConfig = {
    type: 'info',
    message: '',
  };
  alertClosed = true;
  loading = false;

  @ViewChild('alert', { static: false }) alert: NgbAlert;

  constructor(private authService: AuthService, private location: Location) {
  }

  ngOnInit(): void {
  }

  login() {
    this.loading = true;
    this.authService.login({ mail: this.data.mail, password: this.data.password }).subscribe(res => {
      if (res.status === "ok") {
        this.location.go(`usuario/${res.data.role}`);
        window.location.reload();
        this.closeLoginModal.nativeElement.click();
        this.data = {
          mail: "",
          password: "",
          remember: false
        };
      } else if (res.status === "error") {
        this.fireAlert('danger', res.message);
      }
    },
      error => {
        this.fireAlert('danger', error.error.message ? error.error.message : 'Ocurrió un error al iniciar sesión');
      });
  }

  fireAlert(type: string, message: string) {
    this.alertConfig.type = type;
    this.alertConfig.message = message;
    this.alertClosed = false;
    this.loading = false;
    setTimeout(() => {
      if (this.alert) {
        this.alert.close();
      }
    }, 10000);
  }
}
