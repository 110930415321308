import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { SignUpComponent } from './shared/sign-up/sign-up.component';
import { AuthComponent } from './user/common/auth/auth.component';
import { ForgotPasswordComponent } from './user/common/forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'api',
    loadChildren: () => import('./api/api.module').then((m) => m.ApiModule),
  },
  {
    path: 'nosotros',
    loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
  },
  // {
  //   path: 'cotizar',
  //   loadChildren: () => import('./calculate/calculate.module').then((m) => m.CalculateModule),
  // },
  {
    path: 'seguros',
    loadChildren: () => import('./insurance/insurance.module').then((m) => m.InsuranceModule),
  },
  {
    path: 'usuario',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'auth',
    component: AuthComponent
  },
  {
    path: 'signup/:id',
    component: SignUpComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
