<div class="login">
    <div class="modal-header prk-header">
        <h1 class="bold" id="loginModalLabel">Inicia sesión</h1>
        <button type="button" class="close" data-dismiss="modal" #closeLoginModal aria-label="Close"
            id="closeLoginModal">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <ngb-alert #alert [type]="alertConfig.type" *ngIf="!alertClosed" (closed)="alertClosed = true">{{
        alertConfig.message }}
    </ngb-alert>
    <div *ngIf="loading; else elseBlock" class="d-flex justify-content-center">
        <div class="spinner-border text-primary" style="width: 5rem; height: 5rem; margin: 5rem 0;">
            <span class="sr-only">Cargando...</span>
        </div>
    </div>
    <ng-template #elseBlock>
        <p>Bienvenido a Perk, por favor ingrese con los datos personales de su cuenta.</p>
        <form class="prk-form">
            <div class="prk-label">
                <div class="col-3 p-0">Correo</div>
                <div class="col p-0"><input type="text" name="correo" autocomplete="on" placeholder="tu@correo.com"
                        [(ngModel)]="data.mail"></div>
            </div>
            <div class="prk-label">
                <div class="col-3 p-0">Contraseña</div>
                <div class="col p-0"><input type="password" name="password" autocomplete="on" placeholder="***********"
                        [(ngModel)]="data.password"></div>
            </div>
            <div class="login-tools">
                <label><input type="checkbox" name="remember" [(ngModel)]="data.remember">Recordar mis datos</label>
                <a data-dismiss="modal" class="forgot-link" routerLink="/forgot-password">Olvidé mi contraseña</a>
            </div> <button class="prk-btn white my-2 my-sm-0" [disabled]="data.mail == '' || data.password == ''"
                (click)="login()">Iniciar
                sesión</button>
        </form>
    </ng-template>
</div>