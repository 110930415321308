<div class="mt">
    <section class="p-0 bg-forgot">
        <div class="cont">
            <div class="prk-dialog">
                <div class="card prk-content">
                    <h1 class="bold">¿Olvidaste tu <span class="primary">contraseña</span>?</h1>
                    <ngb-alert #alert [type]="alertConfig.type" *ngIf="!alertClosed" (closed)="alertClosed = true">{{
                        alertConfig.message }}
                    </ngb-alert>
                    <div *ngIf="loading; else elseBlock" class="d-flex justify-content-center">
                        <div class="spinner-border text-primary" style="width: 5rem; height: 5rem; margin: 5rem 0;">
                            <span class="sr-only">Cargando...</span>
                        </div>
                    </div>
                    <ng-template #elseBlock>
                    <p>Ingresa el correo electrónico asociado a tu cuenta y te enviaremos una nueva contraseña.</p>
                    <div class="prk-label">
                        <div class="col-3 p-0">Correo</div>
                        <div class="col p-0"><input type="text" placeholder="tu@correo.com" [(ngModel)]="mail"></div>
                    </div>
                    <button class="prk-btn white" [disabled]="mail == ''"
                        (click)="recoverPassword()">Recuperar contraseña</button>
                    </ng-template>
                </div>
            </div>
        </div>
    </section>
</div>