<section class="p-0 mt">
    <div class="bg-404 p-4rem centered">
        <div class="tm cont max-width-1200">
            <h1 class="bold lg"><span class="black">Oops</span>...</h1>
            <h1 class="bold">No hemos podido encontrar la página que estás buscando</h1>
            <p>Lamentamos mucho el inconveniente que esto ha podido causar.</p>
            <p>Al parecer estás intentando ingresar a una página que ha sido borrada o que nunca ha existido.</p>
            <div class="not-found">
                <button class="prk-btn white my-2 my-sm-0" routerLink="/">Volver al Home</button>
            </div>
        </div>
    </div>
</section>