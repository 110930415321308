import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  url = environment.urlBack;

  constructor(private http: HttpClient) { }

  submitUser(userEmail: string, tags: Array<string>) {
    let data = {
      userEmail: userEmail,
      tags: tags
    }
    
    return this.http.post<any>(`${this.url}/newsletter/crear`, data);
  }

  deleteUser(userEmail: string) {
    let data = {
      userEmail: userEmail
    }
    return this.http.post<any>(`${this.url}/newsletter/eliminar`, data);
  }

}
