import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import * as CountriesData from '../country-data.json';

@Component({
  selector: 'app-deportista-form',
  templateUrl: './deportista-form.component.html',
  styleUrls: ['./deportista-form.component.scss']
})
export class DeportistaFormComponent implements OnInit {
  @Input() data: any;
  @Input() plan: any;
  @Input() saveChanges: () => void;
  @Input() blockVigencia: boolean;
  contractor = {
    name: '',
    surname: '',
    rut: '',
    address: '',
    phoneNumber: '',
    email: ''
  };
  beneficiariesData = [{
    name: '',
    surname: '',
    rut: '',
    address: '',
    phoneNumber: '',
    email: '',
    percentage: 100
  }];
  regionsData: any = CountriesData['default'].regions;
  comunesData: any;

  get blockButton() {
    if (this.checkInfo()) {
    } else {
      return true;
    }
  }

  constructor(public utils: UtilsService) { }

  ngOnInit(): void {
  }

  validateRut(entity: string): boolean {
    switch (entity) {
      case 'contratante':
        return !this.utils.validateRut(this.contractor.rut);
      case 'asegurado':
        return !this.utils.validateRut(this.data.rut);
      case 'primerBeneficiario':
        return !this.utils.validateRut(this.beneficiariesData[0].rut);
      case 'segundoBeneficiario':
        return !this.utils.validateRut(this.beneficiariesData[1].rut);
    }
  }

  fillComunes(): void {
    this.regionsData.forEach(region => {
      if (region.name === this.data.region) {
        this.comunesData = region.communes;
        return;
      }
      return;
    });
  }

  addBeneficiarie(): void {
    if (this.beneficiariesData.length === 1) {
      let newBeneficiarieData = {
        name: '',
        surname: '',
        rut: '',
        address: '',
        phoneNumber: '',
        email: '',
        percentage: 0
      };
      this.beneficiariesData.push(newBeneficiarieData);
    } else {
      this.data.moreBeneficiaries = false;
      this.beneficiariesData.pop();
    }
  }

  deleteBeneficiarie(): void {
    this.data.moreBeneficiaries = false;
    this.beneficiariesData.pop();
  }

  pushChanges(): void {
    this.data.beneficiaries = this.beneficiariesData;
    if (this.contractor.name != '') {
      this.data.contractor = this.contractor;
    }
    this.saveChanges();
  }

  checkInfo(): boolean {
    let pass = true;
    // Información cotizante
    if (!(this.data.name != '' && this.data.surname != '' && this.utils.validateRut(this.data.rut) && this.data.region != '' && this.data.commune != '' &&
      this.data.rut != '' && this.data.email != '' && this.data.forecast != '' && this.data.birthdate && this.data.startDate && this.data.address != '')) {
      pass = false;
    }

    // Información seguro
    if (!this.plan.validity) {
      pass = false;
    }

    // Información contratante
    if (this.data.contractorIsNotInsured) {
      if (!(this.contractor.name != '' && this.contractor.surname != '' && this.utils.validateRut(this.contractor.rut) && this.contractor.rut != '' &&
        this.contractor.address != '' && this.contractor.phoneNumber != '' &&
        this.contractor.email != '')) {
        pass = false;
      }
    }
    // Información beneficiarios
    let sumBeneficiaries = 0;
    this.beneficiariesData.forEach(beneficiarie => {
      if (!(beneficiarie.name != '' && beneficiarie.surname != '' && this.utils.validateRut(beneficiarie.rut) && beneficiarie.rut != '' &&
        beneficiarie.address != '' && beneficiarie.phoneNumber != '' &&
        beneficiarie.email != '' && beneficiarie.percentage != 0)) {
        pass = false;
      }
      sumBeneficiaries += beneficiarie.percentage;
    });
    if (sumBeneficiaries != 100) {
      pass = false;
    }
    // Final return
    return pass;
  }

  getVigencia(vigencia) {
    return this.utils.getVigencia(vigencia);
  }
}