import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { MapComponent } from './map/map.component';
import { TimelineComponent } from './timeline/timeline.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { UserNavComponent } from './user-nav/user-nav.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { DragDropDirective } from './drag-drop.directive';
import { DataTableComponent, NgbdSortableHeader } from './data-table/data-table.component';
import { PipesModule } from '../pipes/pipes.module';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { PlanComponent } from './plan/plan.component';
import { TabberComponent } from './tabber/tabber.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { PerkFormsModule } from './perk-forms/perk-forms.module';


@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    NotFoundComponent,
    MapComponent,
    TimelineComponent,
    LoginComponent,
    UserNavComponent,
    UploadFileComponent,
    DragDropDirective,
    DataTableComponent,
    NgbdSortableHeader,
    PlanComponent,
    TabberComponent,
    SignUpComponent
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    MapComponent,
    TimelineComponent,
    LoginComponent,
    UserNavComponent,
    UploadFileComponent,
    DataTableComponent,
    PlanComponent,
    TabberComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PipesModule,
    NgbPaginationModule,
    NgbModule,
    PerkFormsModule
  ]
})
export class SharedModule { }
