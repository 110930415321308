<div style="overflow-x:auto;">
	<table>
		<thead>
			<tr>
				<th scope="col" sortable="nombre" (sort)="onSort($event)">Nombre</th>
				<th scope="col" sortable="rut" (sort)="onSort($event)">RUT</th>
				<th scope="col" sortable="direccion" (sort)="onSort($event)">Dirección</th>
				<th scope="col" sortable="email" (sort)="onSort($event)">Correo</th>
				<th scope="col"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of items">
				<td>{{item.fullName}}</td>
				<td>{{item.rut | rut}}</td>
				<td>{{item.address}}</td>
				<td>{{item.email}}</td>
				<td class="last">
					<button *ngIf="list" type="button" class="prk-btn-td dark" aria-label="List" (click)="list(item)"
						placement="bottom" ngbTooltip="Ver detalles">
						<i class="mdi mdi-format-list-bulleted-square" aria-hidden="true"></i>
					</button>
					<button *ngIf="edit" type="button" class="prk-btn-td gray" aria-label="Pencil" (click)="edit(item)"
						placement="bottom" ngbTooltip="Editar">
						<i class="mdi mdi-pencil" aria-hidden="true"></i>
					</button>
					<button *ngIf="delete" type="button" class="prk-btn-td" aria-label="Plus" (click)="delete(item)"
						placement="bottom" ngbTooltip="Eliminar">
						<i class="mdi mdi-close" aria-hidden="true"></i>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<div class="d-flex justify-content-between p-0">
	<ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="loadItems()">
	</ngb-pagination>
</div>