<div class="prk-form">
    <div class="prk-label">
        <div class="col-3 p-0">Nombre</div>
        <div class="col p-0"><input type="text" #name="ngModel" required placeholder="Nombre"
                [(ngModel)]="data.name">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="prk-form-alert">
                <div *ngIf="name.errors.required">
                    El nombre es requerido
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label" *ngIf="hasRut">
        <div class="col-3 p-0">RUT</div>
        <div class="col p-0"><input type="text" #rut="ngModel" required placeholder="RUT" [ngModel]="data.rut | rut"
                (ngModelChange)="data.rut=utils.cleanRut($event)">
            <div *ngIf="(rut.invalid || validateRut()) && (rut.dirty || rut.touched)" class="prk-form-alert">
                Ingrese un rut válido
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Región</div>
        <div class="col p-0">
            <select #region="ngModel" name="region" [(ngModel)]="data.region" (change)="fillComunes()"
                class="prk-select">
                <option hidden selected [value]="undefined">
                    Selecciona una región
                </option>
                <option *ngFor="let region of regionsData" [value]="region.name">
                    {{ region.name }}
                </option>
            </select>
            <div *ngIf="region.invalid && (region.dirty || region.touched)" class="prk-form-alert">
                <div *ngIf="data.region.errors.required">La región es requerida</div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Comuna</div>
        <div class="col p-0">
            <select #commune="ngModel" [(ngModel)]="data.commune" name="commune" class="prk-select">
                <option hidden selected [value]="undefined">
                    Selecciona una comuna
                </option>
                <option *ngFor="let commune of comunesData" [value]="commune.name">
                    {{ commune.name | titlecase }}
                </option>
            </select>
            <div *ngIf="commune.invalid && (commune.dirty || commune.touched)" class="prk-form-alert">
                <div *ngIf="commune.errors.required">La comuna es requerida</div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Correo</div>
        <div class="col p-0"><input type="email" #email="ngModel" required placeholder="Correo electrónico"
                [(ngModel)]="data.email">
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="prk-form-alert">
                <div *ngIf="email.errors.required">
                    El correo electrónico es requerido
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Dirección</div>
        <div class="col p-0"><input type="text" #address="ngModel" required placeholder="Dirección"
                [(ngModel)]="data.address">
            <div *ngIf="address.invalid && (address.dirty || address.touched)" class="prk-form-alert">
                <div *ngIf="address.errors.required">
                    La dirección es requerida
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Teléfono</div>
        <div class="col p-0"><input type="text" #phoneNumber="ngModel" required placeholder="Número de teléfono"
                [(ngModel)]="data.phoneNumber">
            <div *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)" class="prk-form-alert">
                <div *ngIf="phoneNumber.errors.required">
                    El teléfono es requerido
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label" *ngIf="signup">
        <div class="col-3 p-0">Contraseña</div>
        <div class="col p-0"><input type="password" #password="ngModel" required placeholder="Contraseña"
                [(ngModel)]="data.password">
            <div *ngIf="password.invalid && (password.dirty || password.touched)" class="prk-form-alert">
                <div *ngIf="password.errors.required">
                    La contraseña es obligatoria
                </div>
            </div>
        </div>
    </div>
    <br *ngIf="agreement">
    <label *ngIf="agreement"><input type="checkbox" [(ngModel)]="data.agree">Acepto que quise editar los datos,
        y me hago responsable por la veracidad de la información cambiada.</label>

    <button class="prk-btn center" [disabled]="blockButton" (click)="saveChanges()">Aceptar</button>
</div>