import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import * as CountriesData from '../country-data.json';

@Component({
  selector: 'empresa-form',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {

  @Input() signup: boolean;
  @Input() data: any;
  @Input() agreement: boolean = true;
  @Input() saveChanges: () => void;
  @Input() hasRut: boolean = true;
  regionsData: any = CountriesData['default'].regions;
  comunesData: any;

  get blockButton() {
    if (this.data.name != "" && this.data.address && this.data.phoneNumber != "" && this.data.region &&
      this.data.commune && this.data.email != "") {
      if (this.agreement) {
        if (this.signup) {
          return !this.data.password;
        }
        return !(this.data.agree && !(this.hasRut ? this.validateRut() : false));
      } else {
        return this.hasRut ? this.validateRut() : false;
      }
    } else {
      return true;
    }
  }

  constructor(public utils: UtilsService) { }

  ngOnInit(): void {
  }

  validateRut(): boolean {
    return !this.utils.validateRut(this.data.rut);
  }

  fillComunes(): void {
    this.regionsData.forEach(region => {
      if (region.name === this.data.region) {
        this.comunesData = region.communes;
        return;
      }
      return;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.fillComunes();
    }
  }

}
