<div class="prk-form">
  <div class="prk-label">
    <div class="col-3 p-0">Nombre</div>
    <div class="col p-0"><input type="text" #name="ngModel" (keypress)="keyPress($event)" required
        placeholder="Nombre" [(ngModel)]="data.name">
      <div *ngIf="name.invalid && (name.dirty || name.touched)" class="prk-form-alert">
        <div *ngIf="name.errors.required">
          El nombre es requerido
        </div>
      </div>
    </div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Título</div>
    <div class="col p-0"><input type="text" #title="ngModel" required placeholder="Título" [(ngModel)]="data.title">
      <div *ngIf="title.invalid && (title.dirty || title.touched)" class="prk-form-alert">
        <div *ngIf="title.errors.required">
          El título es requerido
        </div>
      </div>
    </div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Descripción</div>
    <div class="col p-0"><input type="text" #description="ngModel" required placeholder="Descripción"
        [(ngModel)]="data.description">
      <div *ngIf="description.invalid && (description.dirty || description.touched)" class="prk-form-alert">
        <div *ngIf="description.errors.required">
          Agrega una descripción
        </div>
      </div>
    </div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Aseguradora</div>
    <div class="col p-0">
      <select style="width: 100%;" [(ngModel)]="data.aseguradora" #aseguradora="ngModel" required>
        <option hidden selected [value]="undefined">Seleccione una aseguradora</option>
        <option value="HDI">HDI</option>
        <option value="Southbridge">Southbridge</option>
      </select>
      <div *ngIf="aseguradora.invalid && (aseguradora.dirty || aseguradora.touched)" class="prk-form-alert">
        <div *ngIf="aseguradora.errors.required">
          Debe seleccionar una aseguradora
        </div>
      </div>
    </div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Plan</div>
    <div class="col-7 p-0"><input type="text" placeholder="ID plan" [(ngModel)]="newPlan">
    </div>
    <div class="col-2 p-0">
      <button class="prk-btn center condensed" style="padding: 0.2rem 3rem;" [disabled]="blockAddPlanButton"
        (click)="addPlan()">
        <i class="mdi mdi-plus" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Cobertura</div>
    <div class="col-4 p-0"><input type="text" placeholder="Título" [(ngModel)]="newCobertura.title">
    </div>
    <div class="col-3 p-0"><input type="text" placeholder="Descripción" [(ngModel)]="newCobertura.description">
    </div>
    <div class="col-2 p-0">
      <button class="prk-btn center condensed" style="padding: 0.2rem 3rem;" [disabled]="blockAddCoberturaButton"
        (click)="addCobertura()">
        <i class="mdi mdi-plus" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div class="prk-label">
    <div class="col-3 p-0">Exclusión</div>
    <div class="col-4 p-0"><input type="text" placeholder="Título" [(ngModel)]="newExclusion.title">
    </div>
    <div class="col-3 p-0"><input type="text" placeholder="Descripción" [(ngModel)]="newExclusion.description">
    </div>
    <div class="col-2 p-0">
      <button class="prk-btn center condensed" style="padding: 0.2rem 3rem;" [disabled]="blockAddExclusionButton"
        (click)="addExclusion()">
        <i class="mdi mdi-plus" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <h2 *ngIf="data.plans.length > 0" class="mt-4 bold">Planes</h2>
  <table *ngIf="data.plans.length > 0">
    <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data.plans">
        <td>{{item}}</td>
        <td class="last">
          <button type="button" class="prk-btn-td" aria-label="Remove" (click)="removePlan(item)">
            <i class="mdi mdi-close" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <h2 *ngIf="data.coverage.length > 0" class="mt-4 bold">Coberturas</h2>
  <table *ngIf="data.coverage.length > 0">
    <thead>
      <tr>
        <th scope="col">Título</th>
        <th scope="col">Descripción</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data.coverage">
        <td *ngIf="!item.edit">{{item.title}}</td>
        <td *ngIf="item.edit" class="editable"><input type="text" class="editable" #coberturaTitle="ngModel"
            placeholder="Exclusión" [(ngModel)]="item.title"></td>
        <td *ngIf="!item.edit">{{item.description}}</td>
        <td *ngIf="item.edit" class="editable"><input type="text" class="editable" #coberturaDesc="ngModel"
            placeholder="Detalle" [(ngModel)]="item.description"></td>
        <td class="last">
          <button type="button" class="prk-btn-td gray" aria-label="Pencil" (click)="edit(item)">
            <i class="mdi mdi-pencil" aria-hidden="true"></i>
          </button>
          <button type="button" class="prk-btn-td" aria-label="Remove" (click)="removeCobertura(item)">
            <i class="mdi mdi-close" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <h2 *ngIf="data.exclusions.length > 0" class="mt-4 bold">Exclusiones</h2>
  <table *ngIf="data.exclusions.length > 0">
    <thead>
      <tr>
        <th scope="col">Título</th>
        <th scope="col">Descripción</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data.exclusions">
        <td *ngIf="!item.edit">{{item.title}}</td>
        <td *ngIf="item.edit" class="editable"><input type="text" class="editable" #exclusionTitle="ngModel"
            placeholder="Exclusión" [(ngModel)]="item.title"></td>
        <td *ngIf="!item.edit">{{item.description}}</td>
        <td *ngIf="item.edit" class="editable"><input type="text" class="editable" #exclusionDesc="ngModel"
            placeholder="Detalle" [(ngModel)]="item.description"></td>
        <td class="last">
          <button type="button" class="prk-btn-td gray" aria-label="Pencil" (click)="edit(item)">
            <i class="mdi mdi-pencil" aria-hidden="true"></i>
          </button>
          <button type="button" class="prk-btn-td" aria-label="Remove" (click)="removeExclusion(item)">
            <i class="mdi mdi-close" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <button class="prk-btn center" [disabled]="blockButton" (click)="saveChanges()">Aceptar</button>
</div>