<div class="plan">
  <h4 class="plan-name">{{plan.name}}</h4>
  <app-tabber [values]="plan.values" [changeValue]="changeValue" [selectedValue]="selectedValue"></app-tabber>
  <div class="row pricing" *ngIf="valorUnidad">
    <h3 class="price">${{valorUnidad|number}}</h3>
    <div class="column">
      <h3 class="clp">CLP /</h3>
      <h3 class="clp">{{unit}}*</h3>
    </div>
  </div>
  <div class="row pricing">
    <h3 class="uf">{{valorTotal | currency:'UF ':'code'}} / total</h3>
  </div>
  <div class="row pricing" *ngIf="valorDia">
    <h3 class="day">${{valorDia | number }} CLP × día</h3>
  </div>
  <div class="cobertura-container">
    <div class="cobertura" *ngFor="let cobertura of plan.coverage">
      <h5 class="desc">{{cobertura.description}}</h5>
      <h5 class="uf">{{cobertura.amount | currency:'UF ':'code'}}</h5>
    </div>
  </div>
</div>