import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import * as CountriesData from '../country-data.json';

@Component({
  selector: 'app-equipo-form',
  templateUrl: './equipo.component.html',
  styleUrls: ['./equipo.component.scss']
})
export class EquipoComponent implements OnInit {
  @Input() data: any;
  @Input() extraRole: string;
  @Input() saveChanges: () => void;
  regionsData: any = CountriesData['default'].regions;
  comunesData: any;

  titleTexts: any = {
    federacion: 'federación',
    evento: 'evento',
    equipo: 'equipo'
  }

  get blockButton() {
    if (this.checkInfo()) {
    } else {
      return true;
    }
  }

  constructor(public utils: UtilsService) { }

  ngOnInit(): void {
  }

  // validateRut(): boolean {
  //   return !this.utils.validateRut(this.data.rut);
  // }

  checkInfo(): boolean {
    let pass = true;
    // Información cotizante
    if (!(this.data.name != '' && this.data.region && this.data.commune && this.data.email != '' &&
      this.data.address && this.data.phoneNumber)) {
      pass = false;
      return pass;
    }
    return pass;
  }

  fillComunes(): void {
    this.regionsData.forEach(region => {
      if (region.name === this.data.region) {
        this.comunesData = region.communes;
        return;
      }
      return;
    });
  }

  pushChanges(): void {
    this.saveChanges();
  }

}