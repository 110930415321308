import { Component, OnInit } from '@angular/core';
import { NewsletterService } from "../../services/newsletter.service";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private newsletterService: NewsletterService) { }

  email;

  ngOnInit(): void {
  }

  subscribe() {
    if(this.email){
    this.newsletterService.submitUser(this.email, ['newsletter']).subscribe(
      (resp) => {
        if(resp.status == 'ok'){
          Swal.fire(
            '¡Gracias por suscribirte!',
            resp.message,
            'success'
          )
        }
      },
      (error) => {
        Swal.fire(
          'Ha ocurrido un error',
          'No hemos podido agregarte al Newsletter',
          'error'
        )
      }
    );
  }
  }
}
