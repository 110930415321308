import { Location } from '@angular/common';
import { ElementRef, ViewChild } from '@angular/core';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() data: any;
  @ViewChild('openLoginModal') openLoginModal: ElementRef;
  @ViewChild('closeNavbar') closeNavbar: ElementRef;

  isLoggedIn: boolean;
  open = false;
  white = false;
  isAdmin = false;
  homeLink = "/";

  constructor(private authService: AuthService, private location: Location) {
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.loggedIn;
    if (this.isLoggedIn) {
      this.authService.getInfo().subscribe(
        info => {
          this.isAdmin = info.role === "admin" ? true : false;
          this.homeLink = `/usuario/${info.role}`;
        },
        error => {
          this.authService.logout().then(() => {
            this.location.go("/");
            window.location.reload();
          });
        });
    } else {
      this.homeLink = "/";
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    const number =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 30) {
      this.white = true;
    } else {
      this.white = false;
    }
    this.checkBg();
  }
  dataToggle() {
    this.open = !this.open;
    this.checkBg();
  }
  checkBg() {
    let element = document.getElementById('navbar-perk').style;
    if (this.open || this.white) {
      element.backgroundColor = "white";
      element.padding = "0.5rem 1rem";
    } else {
      element.backgroundColor = "transparent";
      element.padding = "1rem 1rem";
    }
  }
  logout() {
    this.authService.logout().then(() => {
      this.location.go("/");
      window.location.reload();
    })
  }
  close() {
    if (window.innerWidth <= 980) {
      this.closeNavbar.nativeElement.click();
    }
  }
  openLogin() {
    this.openLoginModal.nativeElement.click();
  }
}
