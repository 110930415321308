<form class="prk-form">

    <!-- Insured form -->
    <h2 class="text-left">Datos asegurado/a</h2>
    <div class="prk-label">
        <div class="col-3 p-0">Nombre</div>
        <div class="col p-0"><input type="text" #nombre="ngModel" name="nombre" required placeholder="Nombre"
                [(ngModel)]="data.name">
            <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="prk-form-alert">
                <div *ngIf="nombre.errors.required">
                    El nombre es requerido
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Apellido</div>
        <div class="col p-0"><input type="text" #apellido="ngModel" name="apellido" required placeholder="Apellido"
                [(ngModel)]="data.surname">
            <div *ngIf="apellido.invalid && (apellido.dirty || apellido.touched)" class="prk-form-alert">
                <div *ngIf="apellido.errors.required">
                    El apellido es requerido
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">RUT</div>
        <div class="col p-0"><input type="text" #rut="ngModel" name="rut" required placeholder="RUT"
                [ngModel]="data.rut | rut" (ngModelChange)="data.rut=utils.cleanRut($event)">
            <div *ngIf="(rut.invalid || validateRut('asegurado')) && (rut.dirty || rut.touched)" class="prk-form-alert">
                Ingrese un rut válido
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Región</div>
        <div class="col p-0">
            <select #region="ngModel" name="region" [(ngModel)]="data.region" (change)="fillComunes()"
                class="prk-select">
                <option hidden selected [value]="undefined">Selecciona una región</option>
                <option *ngFor="let region of regionsData" [value]="region.name">{{region.name}}</option>
            </select>
            <div *ngIf="region.invalid && (region.dirty || region.touched)" class="prk-form-alert">
                <div *ngIf="data.region.errors.required">
                    La región es requerida
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Comuna</div>
        <div class="col p-0">
            <select #comuna="ngModel" [(ngModel)]="data.commune" name="comuna" class="prk-select">
                <option hidden selected [value]="undefined">Selecciona una comuna</option>
                <option *ngFor="let comuna of comunesData" [value]="comuna.name">{{comuna.name|titlecase}}</option>
            </select>
            <div *ngIf="comuna.invalid && (comuna.dirty || comuna.touched)" class="prk-form-alert">
                <div *ngIf="comuna.errors.required">
                    La comuna es requerida
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Dirección</div>
        <div class="col p-0"><input type="text" #direccion="ngModel" name="direccion" required placeholder="Dirección"
                [(ngModel)]="data.address">
            <div *ngIf="direccion.invalid && (direccion.dirty || direccion.touched)" class="prk-form-alert">
                <div *ngIf="direccion.errors.required">
                    La dirección es requerida
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Teléfono</div>
        <div class="col p-0"><input type="text" #telefono="ngModel" name="telefono" required
                placeholder="Número de teléfono" [(ngModel)]="data.phoneNumber">
            <div *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)" class="prk-form-alert">
                <div *ngIf="telefono.errors.required">
                    El teléfono es requerido
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Correo</div>
        <div class="col p-0"><input type="email" #correo="ngModel" name="correo" required
                placeholder="Correo electrónico" [(ngModel)]="data.email">
            <div *ngIf="correo.invalid && (correo.dirty || correo.touched)" class="prk-form-alert">
                <div *ngIf="correo.errors.required">
                    El correo electrónico es requerido
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Previsión</div>
        <div class="col p-0">
            <select [(ngModel)]="data.forecast" #forecast="ngModel" name="forecast" class="prk-select">
                <option hidden selected [value]="undefined">Selecciona tu previsión</option>
                <option>Fonasa</option>
                <option>Isapre</option>
            </select>
            <div *ngIf="forecast.invalid && (forecast.dirty || forecast.touched)" class="prk-form-alert">
                <div *ngIf="forecast.errors.required">
                    Debe ingresar su previsión
                </div>
            </div>
        </div>
    </div>
    <div class="prk-label">
        <div class="col-3 p-0">Nacimiento</div>
        <div class="col p-0"><input type="date" #birthdate="ngModel" name="nacimiento" required
                placeholder="Fecha de nacimiento" [(ngModel)]="data.birthdate">
            <div *ngIf="birthdate.invalid && (birthdate.dirty || birthdate.touched)" class="prk-form-alert">
                <div *ngIf="birthdate.errors.required">
                    La fecha de nacimiento es requerida
                </div>
            </div>
        </div>
    </div>

    <!-- Plan form -->
    <h2>Plan a contratar</h2>
    <div class="prk-label">
        <div class="col-3 p-0">Plan</div>
        <div class="col p-0">
            <select class="prk-select" [(ngModel)]="plan.name" #seguro="ngModel" name="seguro" [disabled]="true">
                <option [value]="plan.name">{{plan.name | titlecase}}</option>
            </select>
        </div>
    </div>

    <div class="prk-label" *ngIf="plan">
        <div class="col-3 p-0">Vigencia</div>
        <div class="col p-0">
            <select class="prk-select" [disabled]="true" [(ngModel)]="plan.validity"
                #vigencia="ngModel" name="vigencia">
                <option hidden selected [value]="undefined">Seleccione una vigencia</option>
                <option *ngFor="let item of plan.values" [ngValue]="item.validity">{{getVigencia(item.validity)}}
                </option>
            </select>
        </div>
    </div>

    <div class="prk-label">
        <div class="col-3 p-0">Fecha inicio de vigencia</div>
        <div class="col p-0"><input type="date" [disabled]="blockVigencia === true" #inicioVigencia="ngModel"
                name="inicioVigencia" required placeholder="Fecha inicio de vigencia" [(ngModel)]="data.startDate">
            <div *ngIf="inicioVigencia.invalid && (inicioVigencia.dirty || inicioVigencia.touched)"
                class="prk-form-alert">
                <div *ngIf="inicioVigencia.errors.required">
                    La fecha inicio de vigencia es requerida
                </div>
            </div>
        </div>
    </div>

    <br />
    <label><input type="checkbox" [(ngModel)]="data.wantInsuranceEmails" name="wantInsuranceEmails">
        El asegurado/a desea recibir información de la aseguradora.</label>
    <label><input type="checkbox" [(ngModel)]="data.contractorIsNotInsured" name="contractorIsNotInsured">
        El contratante <span class="bold">no</span> es el mismo que el asegurado</label>

    <!-- Contractor form -->
    <div *ngIf="data.contractorIsNotInsured">
        <h2>Datos contratante</h2>
        <div class="prk-label">
            <div class="col-3 p-0">Nombre</div>
            <div class="col p-0"><input type="text" #nombreContratante="ngModel" name="nombreContratante" required
                    placeholder="Nombre contratante" [(ngModel)]="contractor.name">
                <div *ngIf="nombreContratante.invalid && (nombreContratante.dirty || nombreContratante.touched)"
                    class="prk-form-alert">
                    <div *ngIf="nombreContratante.errors.required">
                        El nombre del contratante es requerido
                    </div>
                </div>
            </div>
        </div>
        <div class="prk-label">
            <div class="col-3 p-0">Apellido</div>
            <div class="col p-0"><input type="text" #apellidoContratante="ngModel" name="apellidoContratante" required
                    placeholder="Apellido contratante" [(ngModel)]="contractor.surname">
                <div *ngIf="apellidoContratante.invalid && (apellidoContratante.dirty || apellidoContratante.touched)"
                    class="prk-form-alert">
                    <div *ngIf="apellidoContratante.errors.required">
                        El apellido del contratante es requerido
                    </div>
                </div>
            </div>
        </div>
        <div class="prk-label">
            <div class="col-3 p-0">RUT</div>
            <div class="col p-0"><input type="text" #rutContratante="ngModel" name="rutContratante" required
                    placeholder="Rut contratante" [ngModel]="contractor.rut | rut"
                    (ngModelChange)="contractor.rut=utils.cleanRut($event)">
                <div *ngIf="(rutContratante.invalid || validateRut('contratante')) && (rutContratante.dirty || rutContratante.touched)"
                    class="prk-form-alert">
                    Ingrese un rut válido
                </div>
            </div>
        </div>
        <div class="prk-label">
            <div class="col-3 p-0">Dirección</div>
            <div class="col p-0"><input type="text" #direccionContratante="ngModel" name="direccionContratante" required
                    placeholder="Dirección contratante" [(ngModel)]="contractor.address">
                <div *ngIf="direccionContratante.invalid && (direccionContratante.dirty || direccionContratante.touched)"
                    class="prk-form-alert">
                    <div *ngIf="direccionContratante.errors.required">
                        La dirección es requerida
                    </div>
                </div>
            </div>
        </div>
        <div class="prk-label">
            <div class="col-3 p-0">Correo</div>
            <div class="col p-0"><input type="email" #correoContratante="ngModel" name="correoContratante" required
                    placeholder="Correo electrónico contratante" [(ngModel)]="contractor.email">
                <div *ngIf="correoContratante.invalid && (correoContratante.dirty || correoContratante.touched)"
                    class="prk-form-alert">
                    <div *ngIf="correoContratante.errors.required">
                        El correo electrónico es requerido
                    </div>
                </div>
            </div>
        </div>
        <div class="prk-label">
            <div class="col-3 p-0">Teléfono</div>
            <div class="col p-0"><input type="text" #telefonoContratante="ngModel" name="telefonoContratante" required
                    placeholder="Número de teléfono contratante" [(ngModel)]="contractor.phoneNumber">
                <div *ngIf="telefonoContratante.invalid && (telefonoContratante.dirty || telefonoContratante.touched)"
                    class="prk-form-alert">
                    <div *ngIf="telefonoContratante.errors.required">
                        El teléfono es requerido
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Beneficiarie form -->
    <div>
        <h2>Datos beneficiario/a</h2>
        <p>En caso de muerte accidental, proporciona los datos de la persona que recibirá la indemnización. Ojo que si
            tienes más de un beneficiario, debes asignar el porcentaje que recibirá cada uno.</p>
        <div class="prk-label">
            <div class="col-3 p-0">Nombre</div>
            <div class="col p-0"><input type="text" #nombreBeneficiario="ngModel" name="nombreBeneficiario" required
                    placeholder="Nombre beneficiario/a" [(ngModel)]="beneficiariesData[0].name">
                <div *ngIf="nombreBeneficiario.invalid && (nombreBeneficiario.dirty || nombreBeneficiario.touched)"
                    class="prk-form-alert">
                    <div *ngIf="nombreBeneficiario.errors.required">
                        El nombre del beneficiario es requerido
                    </div>
                </div>
            </div>
        </div>
        <div class="prk-label">
            <div class="col-3 p-0">Apellido</div>
            <div class="col p-0"><input type="text" #apellidoBeneficiario="ngModel" name="apellidoBeneficiario" required
                    placeholder="Apellido beneficiario/a" [(ngModel)]="beneficiariesData[0].surname">
                <div *ngIf="apellidoBeneficiario.invalid && (apellidoBeneficiario.dirty || apellidoBeneficiario.touched)"
                    class="prk-form-alert">
                    <div *ngIf="apellidoBeneficiario.errors.required">
                        El apellido del beneficiario es requerido
                    </div>
                </div>
            </div>
        </div>
        <div class="prk-label">
            <div class="col-3 p-0">RUT</div>
            <div class="col p-0"><input type="text" #rutBeneficiario="ngModel" name="rutBeneficiario" required
                    placeholder="Rut beneficiario/a" [ngModel]="beneficiariesData[0].rut | rut"
                    (ngModelChange)="beneficiariesData[0].rut=utils.cleanRut($event)">
                <div *ngIf="(rutBeneficiario.invalid || validateRut('primerBeneficiario')) && (rutBeneficiario.dirty || rutBeneficiario.touched)"
                    class="prk-form-alert">
                    Ingrese un rut válido
                </div>
            </div>
        </div>
        <div class="prk-label">
            <div class="col-3 p-0">Dirección</div>
            <div class="col p-0"><input type="text" #direccionBeneficiario="ngModel" name="direccionBeneficiario"
                    required placeholder="Dirección beneficiario/a" [(ngModel)]="beneficiariesData[0].address">
                <div *ngIf="direccionBeneficiario.invalid && (direccionBeneficiario.dirty || direccionBeneficiario.touched)"
                    class="prk-form-alert">
                    <div *ngIf="direccionBeneficiario.errors.required">
                        La dirección es requerida
                    </div>
                </div>
            </div>
        </div>
        <div class="prk-label">
            <div class="col-3 p-0">Correo</div>
            <div class="col p-0"><input type="email" #correoBeneficiario="ngModel" name="correoBeneficiario" required
                    placeholder="Correo electrónico beneficiario/a" [(ngModel)]="beneficiariesData[0].email">
                <div *ngIf="correoBeneficiario.invalid && (correoBeneficiario.dirty || correoBeneficiario.touched)"
                    class="prk-form-alert">
                    <div *ngIf="correoBeneficiario.errors.required">
                        El correo electrónico es requerido
                    </div>
                </div>
            </div>
        </div>
        <div class="prk-label">
            <div class="col-3 p-0">Teléfono</div>
            <div class="col p-0"><input type="text" #telefonoBeneficiario="ngModel" name="telefonoBeneficiario" required
                    placeholder="Número de teléfono beneficiario/a" [(ngModel)]="beneficiariesData[0].phoneNumber">
                <div *ngIf="telefonoBeneficiario.invalid && (telefonoBeneficiario.dirty || telefonoBeneficiario.touched)"
                    class="prk-form-alert">
                    <div *ngIf="telefonoBeneficiario.errors.required">
                        El teléfono es requerido
                    </div>
                </div>
            </div>
        </div>
        <div class="prk-label">
            <div class="col-3 p-0">% Monto (máximo 100%)</div>
            <div class="col p-0"><input type="number" min="1" max="100" #montoPrimerBeneficiario="ngModel"
                    name="montoPrimerBeneficiario" required
                    placeholder="Porcentaje del monto que recibirá el beneficiario"
                    [(ngModel)]="beneficiariesData[0].percentage">
                <div *ngIf="montoPrimerBeneficiario.invalid && (montoPrimerBeneficiario.dirty || montoPrimerBeneficiario.touched)"
                    class="prk-form-alert">
                    <div *ngIf="montoPrimerBeneficiario.errors.required">
                        El monto es requerido
                    </div>
                </div>
            </div>
        </div>
        <label><input type="checkbox" [(ngModel)]="data.moreBeneficiaries" (ngModelChange)="addBeneficiarie()"
                name="moreBeneficiaries">
            Hay más de un beneficiario/a (recuerde que los porcentajes deben sumar 100).</label>

        <!-- Second beneficiarie -->
        <div *ngIf="beneficiariesData.length != 1">
            <h2>Datos beneficiario/a</h2>
            <div class="prk-label">
                <div class="col-3 p-0">Nombre</div>
                <div class="col p-0"><input type="text" #nombreBeneficiario2="ngModel" name="nombreBeneficiario2"
                        required placeholder="Nombre beneficiario/a" [(ngModel)]="beneficiariesData[1].name">
                    <div *ngIf="nombreBeneficiario2.invalid && (nombreBeneficiario2.dirty || nombreBeneficiario2.touched)"
                        class="prk-form-alert">
                        <div *ngIf="nombreBeneficiario2.errors.required">
                            El nombre del beneficiario es requerido
                        </div>
                    </div>
                </div>
            </div>
            <div class="prk-label">
                <div class="col-3 p-0">Apellido</div>
                <div class="col p-0"><input type="text" #apellidoBeneficiario2="ngModel" name="apellidoBeneficiario2"
                        required placeholder="Apellido beneficiario/a" [(ngModel)]="beneficiariesData[1].apellido">
                    <div *ngIf="apellidoBeneficiario2.invalid && (apellidoBeneficiario2.dirty || apellidoBeneficiario2.touched)"
                        class="prk-form-alert">
                        <div *ngIf="apellidoBeneficiario2.errors.required">
                            El apellido del beneficiario es requerido
                        </div>
                    </div>
                </div>
            </div>
            <div class="prk-label">
                <div class="col-3 p-0">RUT</div>
                <div class="col p-0"><input type="text" #rutBeneficiario2="ngModel" name="rutBeneficiario2" required
                        placeholder="Rut beneficiario/a" [ngModel]="beneficiariesData[1].rut"
                        (ngModelChange)="beneficiariesData[1].rut=utils.cleanRut($event)">
                    <div *ngIf="(rutBeneficiario2.invalid || validateRut('segundoBeneficiario')) && (rutBeneficiario2.dirty || rutBeneficiario2.touched)"
                        class="prk-form-alert">
                        Ingrese un rut válido
                    </div>
                </div>
            </div>
            <div class="prk-label">
                <div class="col-3 p-0">Dirección</div>
                <div class="col p-0"><input type="text" #direccionBeneficiario2="ngModel" name="direccionBeneficiario2"
                        required placeholder="Dirección beneficiario/a" [(ngModel)]="beneficiariesData[1].address">
                    <div *ngIf="direccionBeneficiario2.invalid && (direccionBeneficiario2.dirty || direccionBeneficiario2.touched)"
                        class="prk-form-alert">
                        <div *ngIf="direccionBeneficiario2.errors.required">
                            La dirección es requerida
                        </div>
                    </div>
                </div>
            </div>
            <div class="prk-label">
                <div class="col-3 p-0">Correo</div>
                <div class="col p-0"><input type="email" #correoBeneficiario2="ngModel" name="correoBeneficiario2"
                        required placeholder="Correo electrónico beneficiario/a"
                        [(ngModel)]="beneficiariesData[1].email">
                    <div *ngIf="correoBeneficiario2.invalid && (correoBeneficiario2.dirty || correoBeneficiario2.touched)"
                        class="prk-form-alert">
                        <div *ngIf="correoBeneficiario2.errors.required">
                            El correo electrónico es requerido
                        </div>
                    </div>
                </div>
            </div>
            <div class="prk-label">
                <div class="col-3 p-0">Teléfono</div>
                <div class="col p-0"><input type="text" #telefonoBeneficiario2="ngModel" name="telefonoBeneficiario2"
                        required placeholder="Número de teléfono beneficiario/a"
                        [(ngModel)]="beneficiariesData[1].phoneNumber">
                    <div *ngIf="telefonoBeneficiario2.invalid && (telefonoBeneficiario2.dirty || telefonoBeneficiario2.touched)"
                        class="prk-form-alert">
                        <div *ngIf="telefonoBeneficiario2.errors.required">
                            El teléfono es requerido
                        </div>
                    </div>
                </div>
            </div>
            <div class="prk-label">
                <div class="col-3 p-0">% Monto (máximo 100%)</div>
                <div class="col p-0"><input type="number" min="0" [max]="100 - beneficiariesData[0].percentage"
                        #montoPrimerBeneficiario2="ngModel" name="montoPrimerBeneficiario2" required
                        placeholder="Porcentaje del monto que recibirá el beneficiario"
                        [(ngModel)]="beneficiariesData[1].percentage">
                    <div *ngIf="montoPrimerBeneficiario2.invalid && (montoPrimerBeneficiario2.dirty || montoPrimerBeneficiario2.touched)"
                        class="prk-form-alert">
                        <div *ngIf="montoPrimerBeneficiario2.errors.required">
                            El monto es requerido
                        </div>
                    </div>
                </div>
            </div>
            <button class="prk-btn float-right" (click)="deleteBeneficiarie()">Eliminar beneficiario</button>
        </div>
    </div>
    <button class="prk-btn center" [disabled]="blockButton" (click)="pushChanges()">Aceptar</button>
</form>