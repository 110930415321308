import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import * as CountriesData from '../country-data.json';


@Component({
  selector: 'persona-form',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.scss']
})
export class PersonaComponent implements OnInit {

  @Input() edit: boolean;
  @Input() data: any;
  @Input() seguros: any[];
  @Input() saveChanges: () => void;
  @Input() agreement: boolean = true;
  @Input() create: boolean = false;
  regionsData: any = CountriesData['default'].regions;
  comunesData: any;

  get blockButton() {
    if (this.data.nombre != "" && this.data.apellido != "" && this.data.rut != "" && this.data.email != "" && this.data.birthdate != "" && this.data.direccion != "") {
      if (this.agreement) {
        return !(this.data.agree && !this.validateRut(this.data.rut));
      } else {
        return this.validateRut(this.data.rut);
      }
    } else {
      return true;
    }
  }

  constructor(public utils: UtilsService) { }

  ngOnInit(): void {
  }

  validateRut(rut: string): boolean {
    return !this.utils.validateRut(rut);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.fillComunes();
      if (this.data.infoSeguro && this.data.infoSeguro.nombre && this.seguros) {
        this.data.seguro = this.seguros.find(seguro => seguro.nombre === this.data.infoSeguro.nombre)._id;
      }
    }
  }

  fillComunes(): void {
    this.regionsData.forEach(region => {
      if (region.name === this.data.region) {
        this.comunesData = region.communes;
        return;
      }
      return;
    });
  }

}
