<section class="p-0">
    <div class="row">
        <div class="col-6 p-0 centered align-end">
            <div class="map max-width-600">
                <div class="map-item">
                    <div class="col-1 centered">
                        <img src="assets/images/home/locate.svg" alt="Location" />
                    </div>
                    <div class="col">
                        Santiago, Chile
                    </div>
                </div>
                <div class="map-item">
                    <div class="col-1 centered">
                        <img src="assets/images/home/telephone.svg" alt="Phone number" />
                    </div>
                    <div class="col">
                        <a href="tel:+56978882773"> +56 9 7888 2773</a> / <a href="tel:+56950126578">+56 9 5012
                            6578</a>
                    </div>
                </div>
                <div class="map-item">
                    <div class="col-1 centered">
                        <img src="assets/images/home/mail.svg" alt="Email address" />
                    </div>
                    <div class="col">
                        <a href="mailto:ventas@perk.cl">ventas@perk.cl</a>
                    </div>
                </div>
                <div class="map-item">
                    <a href="https://www.linkedin.com/company/perkseguros/" target="_blank"><i class="mdi mdi-linkedin"></i></a>
                    <a href="https://www.instagram.com/perk_cl/" target="_blank"><i class="mdi mdi-instagram"></i></a>
                    <a href="https://www.facebook.com/perkchile/" target="_blank"><i class="mdi mdi-facebook"></i></a>
                </div>
            </div>
        </div>
        <div class="col-6 p-0 iframe-rwd">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.65455516616!2d-70.66695798426468!3d-33.45830540530249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c5c258777d5d%3A0x76d0769fe1ab9176!2sOpenBeauchef%20-%20U.%20de%20Chile!5e0!3m2!1ses-419!2scl!4v1621974740127!5m2!1ses-419!2scl"
                allowfullscreen></iframe>
        </div>
    </div>
</section>