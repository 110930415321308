import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {

  @Input() plan: any;
  @Input() uf: number;
  selectedValue: string;

  get valorUnidad() {
    var vigencia = this.plan.values.find(value => value._id === this.selectedValue);
    switch (vigencia.validity.unit) {
      case 'D':
        // return Math.ceil((vigencia.price * this.uf) / vigencia.vigencia.cantidad);
        return Math.ceil(vigencia.price * this.uf);
      case 'M':
        return Math.ceil((vigencia.price * this.uf) / vigencia.validity.quantity);
      case 'Y':
        return Math.ceil((vigencia.price * this.uf) / 12 * vigencia.validity.quantity);
      default:
        return null;
    }
  }

  get valorDia() {
    var vigencia = this.plan.values.find(value => value._id === this.selectedValue);
    if (vigencia.validity.unit === 'D') {
      return Math.ceil((vigencia.price * this.uf) / vigencia.validity.quantity);
    } else {
      return null;
    }
  }

  get unit() {
    var vigencia = this.plan.values.find(value => value._id === this.selectedValue);
    switch (vigencia.validity.unit) {
      case 'D':
        return 'TOTAL';
      case 'M':
        return 'MES';
      case 'Y':
        return 'MES';
    }
  }

  get valorTotal() {
    var vigencia = this.plan.values.find(value => value._id === this.selectedValue);
    return vigencia.price;
  }

  constructor() { }

  ngOnInit(): void {
    this.changeValue(null);
  }

  changeValue = (newValue): void => {
    const currentIndex = this.plan.values.findIndex(valor => valor._id === this.selectedValue);
    switch (newValue) {
      case true:
        if (currentIndex !== (this.plan.values.length - 1)) {
          this.selectedValue = this.plan.values[currentIndex + 1]._id;
        }
        break;
      case false:
        if (currentIndex !== 0) {
          this.selectedValue = this.plan.values[currentIndex - 1]._id;
        }
        break;
      case null:
        this.selectedValue = this.plan.values[0]._id;
        break;
    }
  }

}
