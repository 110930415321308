<div class="timeline">
    <div class="timeline-item" *ngIf="firstElement">
        <div class="timeline-icon first">
            <img [src]="firstElement.image" class="timeline-image" alt="Cotiza">
        </div>
        <div class="timeline-desc">{{firstElement.name}}</div>
    </div>
    <div class="timeline-item" *ngFor="let element of elements">
        <div class="timeline-icon">
            <img [src]="element.image" class="timeline-image" alt="Registro">
        </div>
        <div class="timeline-desc">{{element.name}}</div>
    </div>
    <div class="timeline-item" *ngIf="lastElement">
        <div class="timeline-icon last">
            <img [src]="lastElement.image" class="timeline-image" alt="Pago">
        </div>
        <div class="timeline-desc">{{lastElement.name}}</div>
    </div>
</div>