import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private url = environment.urlBack;

  constructor(private http: HttpClient) { }

  validateRut(rut): boolean {
    if (typeof rut !== 'string' || !/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
      return false;
    }
    rut = this.cleanRut(rut);
    var t = parseInt(rut.slice(0, -1), 10);
    var m = 0;
    var s = 1;
    while (t > 0) {
      s = (s + (t % 10) * (9 - m++ % 6)) % 11;
      t = Math.floor(t / 10);
    }

    var v = s > 0 ? '' + (s - 1) : 'K';
    return v === rut.slice(-1);
  }

  cleanRut(rut) {
    return typeof rut === 'string'
      ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
      : '';
  }

  sendContactForm(form) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(`${this.url}/contact`, form, { headers: headers });
  }

  getUF() {
    return this.http.get<any>('https://mindicador.cl/api/uf');
  }

  getVigencia = (validity: any): string => {
    var vigenciaString = '';
    switch (validity.unit) {
      case 'D':
        vigenciaString = `${validity.quantity} día${parseInt(validity.quantity) === 1 ? '' : 's'}`;
        break;
      case 'M':
        vigenciaString = `${validity.quantity} mes${parseInt(validity.quantity) === 1 ? '' : 'es'}`;
        break;
      case 'Y':
        vigenciaString = `${validity.quantity} año${parseInt(validity.quantity) === 1 ? '' : 's'}`;
        break;
    }
    return vigenciaString;
  }

  calculateTimeUntil(num) {
    const currentTime = Math.floor(Date.now() / 1000);
    const timestamp = parseInt(num);
    const timeUntil = timestamp - currentTime;

    let second, seconds, timeTo, minute, hour, day, mins, hrs, days;
    second = 1;
    minute = 60;
    hour = 3600;
    day = 86400;

    function units(divider, total = timeUntil) {
      let obj = Object.create(null);
      if (total && total > divider) {
        obj.units = Math.floor(total / divider);
        obj.overflow = total % divider;
        return obj;
      }
      obj.units = 0;
      obj.overflow = total;
      return obj;
    }

    timeTo = Object.create(null);

    if (timeUntil > 1) {
      // days
      days = units(day);
      timeTo.days = days.units;

      // hours
      hrs = units(hour, days.overflow);
      timeTo.hrs = hrs.units;

      // mins
      mins = units(minute, hrs.overflow);
      timeTo.mins = mins.units;

      // seconds
      seconds = units(second, mins.overflow);
      timeTo.seconds = seconds.units;

    } else {
      timeTo.days = 0;
      timeTo.hrs = 0;
      timeTo.mins = 0;
      timeTo.seconds = 0;
    }

    return timeTo
  }

}
