import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'grupo-form',
  templateUrl: './grupo.component.html',
  styleUrls: ['./grupo.component.scss']
})
export class GrupoComponent implements OnInit {

  @Input() data: any;
  @Input() saveChanges: () => void;
  newPlan: string = '';
  newCobertura: any = {
    title: '',
    description: ''
  };
  newExclusion: any = {
    title: '',
    description: ''
  };

  get blockButton() {
    if (this.data.name != "" && this.data.title != "" && this.data.description != "" && this.data.insuranceCarrier != "") {
      return false;
    } else {
      return true;
    }
  }

  get blockAddPlanButton() {
    return (this.newPlan === '');
  }

  get blockAddCoberturaButton() {
    return (this.newCobertura.title === '');
  }

  get blockAddExclusionButton() {
    return (this.newExclusion.title === '');
  }

  constructor() { }

  ngOnInit(): void {
  }

  addPlan() {
    this.data.plans.push(JSON.parse(JSON.stringify(this.newPlan)));
    this.newPlan = '';
  }

  addCobertura() {
    this.data.coverage.push(JSON.parse(JSON.stringify(this.newCobertura)));
    this.newCobertura = {
      title: '',
      description: ''
    };
  }

  addExclusion() {
    this.data.exclusions.push(JSON.parse(JSON.stringify(this.newExclusion)));
    this.newExclusion = {
      title: '',
      description: ''
    };
  }

  removePlan(item) {
    this.data.plans.splice(this.data.plans.indexOf(item), 1);
  }

  removeCobertura(item) {
    this.data.coverage.splice(this.data.coverage.findIndex(element => element.titulo === item.titulo), 1);
  }

  removeExclusion(item) {
    this.data.exclusions.splice(this.data.exclusions.findIndex(element => element.titulo === item.titulo), 1);
  }

  keyPress(event: KeyboardEvent) {
    var charCode = event.which ? event.which : event.keyCode;
    if (
      (charCode > 64 && charCode < 91) ||
      (charCode > 96 && charCode < 123) ||
      charCode == 8 ||
      charCode == 46 ||
      charCode == 45 ||
      (charCode >= 48 && charCode <= 57)
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.data.coverage.forEach(item => {
        item.edit = false;
      });
      this.data.exclusions.forEach(exclusion => {
        exclusion.edit = false;
      });
    }
  }

  edit = (item: any) => {
    item.edit = !item.edit;
  }

}
