import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HotjarService {
  public getInstance() {
    return window['hj'] = window['hj'] || this.getHotjarQueue(); 
  }
  public trigger(eventName: string) {
    try {
      this.getInstance()('trigger', eventName);
    } catch (err) {
      console.error(err);
    }
  }

  public vpv(url: string) {
    try {
      this.getInstance()('vpv', url);
    } catch (err) {
      console.error(err);
    }
  }

  private getHotjarQueue() {
    return function () {
      (window['hj']['q'] = window['hj']['q'] || []).push(arguments);
    };
  }
}
