import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  mail: string;
  loading = false;
  alertConfig = {
    type: 'info',
    message: '',
  };
  alertClosed = true;

  @ViewChild('alert', { static: false }) alert: NgbAlert;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  recoverPassword() {
    this.authService.recoverPassword(this.mail).subscribe(
      res => {
        this.fireAlert('success', 'Una nueva contraseña se ha enviado a su correo');
      },
      error => {
        this.fireAlert('danger', error.error.message ? error.error.message : 'Ocurrió un error al recuperar su contraseña');
      })
  }

  fireAlert(type: string, message: string) {
    this.alertConfig.type = type;
    this.alertConfig.message = message;
    this.alertClosed = false;
    this.loading = false;
    setTimeout(() => {
      if (this.alert) {
        this.alert.close();
      }
    }, 10000);
  }

}
